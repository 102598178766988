.Footer {
  background-color: #fff;
  color: #818181;
  font-size: 0.75rem;
  letter-spacing: 0.5rem;
  margin-right: -0.5rem;
  padding: 16px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 768px) {
    letter-spacing: 1.5rem;
    margin-right: -1.5rem;
    min-height: 5rem;
  }
}
