body {
  background-color: #fff;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Raleway', sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #323232;
  text-decoration: none;
}

h1,
h3,
h5 {
  font-family: 'Raleway', sans-serif;
}

h2,
h4,
h6 {
  font-family: 'Playfair Display', serif;
}
