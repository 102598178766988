.Header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5rem 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  .title-back {
    align-items: center;
    display: flex;
    justify-content: center;

    h1 {
      color: #444;
      font-family: 'Raleway', sans-serif;
      font-size: 2.25rem;
      font-weight: 800;
      letter-spacing: 1.25rem;
      line-height: 1;
      padding: 0;
      margin: 0;
      margin-right: -1.25rem;

      @media (min-width: 768px) {
        color: #333;
        font-size: 5rem;
        letter-spacing: 2rem;
      }
    }
  }

  .title-front {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    letter-spacing: 0.5rem;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;

    h2 {
      display: inline-block;
      font-family: 'Raleway', sans-serif;
      font-size: 1.5rem;
      font-weight: 800;
      margin: 0;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 2.5rem;
      }
    }
  }
}
