body {
  background-color: #fff;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Raleway', sans-serif; }

a {
  color: #fff;
  text-decoration: none; }

a:hover {
  color: #323232;
  text-decoration: none; }

h1,
h3,
h5 {
  font-family: 'Raleway', sans-serif; }

h2,
h4,
h6 {
  font-family: 'Playfair Display', serif; }

.AmazonIcon {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  grid-area: amazon;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  justify-self: start; }
  .AmazonIcon .amazon-link {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .AmazonIcon .amazon-link .amazon-logo {
      display: none;
      fill: #929292; }
      @media (min-width: 768px) {
        .AmazonIcon .amazon-link .amazon-logo {
          display: block; } }
    @media (min-width: 768px) {
      .AmazonIcon .amazon-link {
        background-color: #323232;
        border-radius: 50%;
        height: 50px;
        width: 50px; }
        .AmazonIcon .amazon-link .mobile-link {
          display: none; } }
    .AmazonIcon .amazon-link:hover {
      background-color: #fff; }
      .AmazonIcon .amazon-link:hover .amazon-logo {
        fill: #323232; }

.BookCover {
  grid-area: cover;
  justify-self: center; }
  @media (max-width: 767px) {
    .BookCover {
      margin-bottom: 16px; } }
  .BookCover img {
    border: 2px solid #929292;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22), 0 20px 90px 20px rgba(50, 50, 50, 0.8);
    margin: 0 auto;
    max-width: 155px; }

.Icon {
  margin: 8px; }

svg:not(:root).fa {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.125em; }

.fa {
  height: 1em;
  width: 1em; }
  .fa.fa-inverse {
    color: #fff; }
  .fa.fa-lg {
    height: 1.33333em;
    width: 1.33333em; }
  .fa.fa-xs {
    height: 0.75em;
    width: 0.75em; }
  .fa.fa-sm {
    height: 0.875em;
    width: 0.875em; }
  .fa.fa-1x {
    height: 16px;
    width: 16px; }
  .fa.fa-2x {
    height: 32px;
    width: 32px; }
  .fa.fa-3x {
    height: 48px;
    width: 48px; }
  .fa.fa-4x {
    height: 64px;
    width: 64px; }
  .fa.fa-5x {
    height: 80px;
    width: 80px; }
  .fa.fa-6x {
    height: 96px;
    width: 96px; }
  .fa.fa-7x {
    height: 112px;
    width: 112px; }
  .fa.fa-8x {
    height: 128px;
    width: 128px; }
  .fa.fa-9x {
    height: 144px;
    width: 144px; }
  .fa.fa-10x {
    height: 160px;
    width: 160px; }

.Footer {
  background-color: #fff;
  color: #818181;
  font-size: 0.75rem;
  letter-spacing: 0.5rem;
  margin-right: -0.5rem;
  padding: 16px;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .Footer {
      letter-spacing: 1.5rem;
      margin-right: -1.5rem;
      min-height: 5rem; } }

.Spinner {
  margin: 200px auto;
  position: relative; }

.books_list {
  margin: 0 auto;
  padding: 0;
  width: 300px; }

.book_item {
  -webkit-animation: travel 2500ms linear infinite;
          animation: travel 2500ms linear infinite;
  background-color: #3aaa1a;
  border: 5px solid #f1f1f1;
  box-sizing: border-box;
  height: 120px;
  list-style: none;
  opacity: 0;
  position: absolute;
  top: -120px;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: translateX(300px);
          transform: translateX(300px);
  width: 40px; }
  .book_item.first {
    height: 140px;
    top: -140px; }
    .book_item.first::before, .book_item.first::after {
      background-color: #f1f1f1;
      content: '';
      left: 0;
      height: 5px;
      position: absolute;
      top: 10px;
      width: 100%; }
    .book_item.first::after {
      bottom: 10px;
      top: auto;
      top: initial; }
  .book_item.second::before, .book_item.second::after, .book_item.fifth::before, .book_item.fifth::after {
    border-bottom: 5px solid #f1f1f1;
    border-top: 5px solid #f1f1f1;
    box-sizing: border-box;
    content: '';
    height: 17.5px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 100%; }
  .book_item.second::after, .book_item.fifth::after {
    bottom: 10px;
    top: auto;
    top: initial; }
  .book_item.third::before, .book_item.third::after {
    border: 5px solid #f1f1f1;
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    height: 12px;
    left: 9px;
    position: absolute;
    top: 10px;
    width: 12px; }
  .book_item.third::after {
    bottom: 10px;
    top: auto;
    top: initial; }
  .book_item.fourth {
    height: 130px;
    top: -130px; }
    .book_item.fourth::before {
      border-bottom: 5px solid #f1f1f1;
      border-top: 5px solid #f1f1f1;
      box-sizing: border-box;
      content: '';
      height: 17.5px;
      left: 0;
      position: absolute;
      top: 46px;
      width: 100%; }
  .book_item.fifth {
    height: 100px;
    top: -100px; }
  .book_item.sixth {
    height: 140px;
    top: -140px; }
    .book_item.sixth::before {
      background-color: #f1f1f1;
      bottom: 31px;
      box-sizing: border-box;
      content: '';
      height: 5px;
      left: 0;
      position: absolute;
      width: 100%; }
    .book_item.sixth::after {
      border: 5px solid #f1f1f1;
      border-radius: 50%;
      bottom: 10px;
      box-sizing: border-box;
      content: '';
      height: 12px;
      left: 9px;
      position: absolute;
      width: 12px; }
  .book_item:nth-child(2) {
    -webkit-animation-delay: 416.66667ms;
            animation-delay: 416.66667ms; }
  .book_item:nth-child(3) {
    -webkit-animation-delay: 833.33333ms;
            animation-delay: 833.33333ms; }
  .book_item:nth-child(4) {
    -webkit-animation-delay: 1250ms;
            animation-delay: 1250ms; }
  .book_item:nth-child(5) {
    -webkit-animation-delay: 1666.66667ms;
            animation-delay: 1666.66667ms; }
  .book_item:nth-child(6) {
    -webkit-animation-delay: 2083.33333ms;
            animation-delay: 2083.33333ms; }

.shelf {
  background-color: #f1f1f1;
  height: 5px;
  margin: 0 auto;
  position: relative;
  width: 300px; }
  .shelf::before, .shelf::after {
    -webkit-animation: move 250ms linear infinite;
            animation: move 250ms linear infinite;
    content: '';
    left: 5%;
    height: 100%;
    background: #1a1a1a;
    background-image: radial-gradient(rgba(255, 255, 255, 0.5) 30%, transparent 0);
    background-size: 10px 10px;
    background-position: 0 -2.5px;
    position: absolute;
    top: 200%;
    width: 100%; }
  .shelf::after {
    left: 7.5%;
    top: 400%; }

@-webkit-keyframes move {
  from {
    background-position-x: 0; }
  to {
    background-position-x: 10px; } }

@keyframes move {
  from {
    background-position-x: 0; }
  to {
    background-position-x: 10px; } }

@-webkit-keyframes travel {
  0% {
    opacity: 0;
    -webkit-transform: translateX(300px) rotateZ(0deg) scaleY(1);
            transform: translateX(300px) rotateZ(0deg) scaleY(1); }
  6.5% {
    -webkit-transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
            transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1); }
  8.8% {
    -webkit-transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
            transform: translateX(273.6px) rotateZ(0deg) scaleY(1); }
  10% {
    opacity: 1;
    -webkit-transform: translateX(270px) rotateZ(0deg);
            transform: translateX(270px) rotateZ(0deg); }
  17.6% {
    -webkit-transform: translateX(247.2px) rotateZ(-30deg);
            transform: translateX(247.2px) rotateZ(-30deg); }
  45% {
    -webkit-transform: translateX(165px) rotateZ(-30deg);
            transform: translateX(165px) rotateZ(-30deg); }
  49.5% {
    -webkit-transform: translateX(151.5px) rotateZ(-45deg);
            transform: translateX(151.5px) rotateZ(-45deg); }
  61.5% {
    -webkit-transform: translateX(115.5px) rotateZ(-45deg);
            transform: translateX(115.5px) rotateZ(-45deg); }
  67% {
    -webkit-transform: translateX(99px) rotateZ(-60deg);
            transform: translateX(99px) rotateZ(-60deg); }
  76% {
    -webkit-transform: translateX(72px) rotateZ(-60deg);
            transform: translateX(72px) rotateZ(-60deg); }
  83.5% {
    opacity: 1;
    -webkit-transform: translateX(49.5px) rotateZ(-90deg);
            transform: translateX(49.5px) rotateZ(-90deg); }
  90% {
    opacity: 0; }
  100% {
    opacity: 0;
    -webkit-transform: translateX(0) rotateZ(-90deg);
            transform: translateX(0) rotateZ(-90deg); } }

@keyframes travel {
  0% {
    opacity: 0;
    -webkit-transform: translateX(300px) rotateZ(0deg) scaleY(1);
            transform: translateX(300px) rotateZ(0deg) scaleY(1); }
  6.5% {
    -webkit-transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
            transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1); }
  8.8% {
    -webkit-transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
            transform: translateX(273.6px) rotateZ(0deg) scaleY(1); }
  10% {
    opacity: 1;
    -webkit-transform: translateX(270px) rotateZ(0deg);
            transform: translateX(270px) rotateZ(0deg); }
  17.6% {
    -webkit-transform: translateX(247.2px) rotateZ(-30deg);
            transform: translateX(247.2px) rotateZ(-30deg); }
  45% {
    -webkit-transform: translateX(165px) rotateZ(-30deg);
            transform: translateX(165px) rotateZ(-30deg); }
  49.5% {
    -webkit-transform: translateX(151.5px) rotateZ(-45deg);
            transform: translateX(151.5px) rotateZ(-45deg); }
  61.5% {
    -webkit-transform: translateX(115.5px) rotateZ(-45deg);
            transform: translateX(115.5px) rotateZ(-45deg); }
  67% {
    -webkit-transform: translateX(99px) rotateZ(-60deg);
            transform: translateX(99px) rotateZ(-60deg); }
  76% {
    -webkit-transform: translateX(72px) rotateZ(-60deg);
            transform: translateX(72px) rotateZ(-60deg); }
  83.5% {
    opacity: 1;
    -webkit-transform: translateX(49.5px) rotateZ(-90deg);
            transform: translateX(49.5px) rotateZ(-90deg); }
  90% {
    opacity: 0; }
  100% {
    opacity: 0;
    -webkit-transform: translateX(0) rotateZ(-90deg);
            transform: translateX(0) rotateZ(-90deg); } }

.book_item:nth-child(1) {
  background: #217dbb;
  border-color: #196090; }

.book_item:nth-child(2) {
  background: #bf6516;
  border-color: #924d10; }

.book_item:nth-child(3) {
  background: #1e8449;
  border-color: #145b32; }

.book_item:nth-child(4) {
  background: #703688;
  border-color: #522764; }

.book_item:nth-child(5) {
  background: #107360;
  border-color: #0a463a; }

.book_item:nth-child(6) {
  background: #962d22;
  border-color: #6d2018; }

.UserPicked {
  grid-area: user;
  margin-bottom: 8px; }
  @media (min-width: 768px) {
    .UserPicked {
      -webkit-align-content: center;
              align-content: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      height: 100%;
      -webkit-justify-content: center;
              justify-content: center;
      margin: 0; } }
  .UserPicked .user-img {
    display: none; }
    @media (min-width: 768px) {
      .UserPicked .user-img {
        display: block; } }
  .UserPicked .user-name {
    font-weight: bold; }
    @media (min-width: 768px) {
      .UserPicked .user-name {
        font-weight: nomral;
        text-align: center; } }
  @media (min-width: 768px) {
    .UserPicked .user-info {
      display: none; } }

@media (min-width: 1200px) {
  .UserPicked .user-img {
    max-width: 125px;
    max-height: 125px;
    margin: 0 auto; } }

.AddBookForm input {
  min-width: 280px;
  width: 100%; }

.AddBookForm label {
  display: block;
  font-weight: bold;
  margin-bottom: 4px; }

.AddBookForm select:not(:first-of-type) {
  margin-left: 8px; }

.AddBookForm .error {
  color: #aa1212;
  margin-top: 4px; }

.AddBookForm .form-group {
  display: block;
  margin: 0 8px 16px; }

.AddBookForm .modal-btn {
  margin: 8px 16px 8px 0;
  padding: 8px 16px; }

.AddBookForm .modal-btn-primary {
  background: #4712aa;
  color: #fff; }

.AddBookForm .modal-btn-secondary {
  background: #dadada;
  color: #222; }

.modal-header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.close {
  background: transparent;
  border: 0;
}

.AddUserForm .error {
  color: #aa1212;
  margin-top: 4px; }

.AddUserForm .modal-btn {
  margin: 8px 16px 8px 0;
  padding: 8px 16px; }

.AddUserForm .modal-btn-primary {
  background: #4712aa;
  color: #fff; }

.AddUserForm .modal-btn-secondary {
  background: #dadada;
  color: #222; }

.AddUserForm .password-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.modal-header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.close {
  background: transparent;
  border: 0;
}

.AllRatings {
  list-style-type: none;
  min-width: 130px;
  padding: 16px;
  padding-bottom: 0; }
  .AllRatings .all-ratings__item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.Rating {
  grid-area: rating; }
  @media (max-width: 767px) {
    .Rating {
      justify-self: center; } }
  .Rating .btn-link {
    color: #fff; }
    .Rating .btn-link:hover {
      color: #b3b3b3; }
  .Rating .rating-wrapper {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }
  .Rating .book-rating {
    -webkit-align-items: baseline;
            align-items: baseline;
    cursor: default;
    display: -webkit-flex;
    display: flex;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .Rating .book-rating-max {
      color: #929292;
      font-size: 16px; }
    .Rating .book-rating-total {
      color: #fff;
      font-size: 30px; }
      @media (min-width: 768px) {
        .Rating .book-rating-total {
          font-size: 36px; } }
  .Rating .rate-info {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }

.star {
  width: 40px; }
  @media (min-width: 768px) {
    .star {
      width: 32px; } }
  .star.star-small {
    margin: 2px 0 4px 4px;
    width: 14px; }

.Rating {
  grid-area: rating; }
  @media (max-width: 767px) {
    .Rating {
      justify-self: center; } }
  .Rating .btn-link {
    color: #fff; }
    .Rating .btn-link:hover {
      color: #b3b3b3; }
  .Rating .rating-wrapper {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }
  .Rating .book-rating {
    -webkit-align-items: baseline;
            align-items: baseline;
    cursor: default;
    display: -webkit-flex;
    display: flex;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .Rating .book-rating-max {
      color: #929292;
      font-size: 16px; }
    .Rating .book-rating-total {
      color: #fff;
      font-size: 30px; }
      @media (min-width: 768px) {
        .Rating .book-rating-total {
          font-size: 36px; } }
  .Rating .rate-info {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }

.star {
  width: 40px; }
  @media (min-width: 768px) {
    .star {
      width: 32px; } }
  .star.star-small {
    margin: 2px 0 4px 4px;
    width: 14px; }

.Book {
  -webkit-align-items: start;
          align-items: start;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas: 'cover title title' 'cover author author' 'cover date date' 'cover user user' 'cover amazon amazon' 'cover . .' 'rating rating rating';
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 0 auto 60px; }
  @media (min-width: 768px) {
    .Book {
      margin: 32px auto; } }
  @media (min-width: 768px) {
    .Book {
      grid-template-columns: 50px 155px 300px 100px;
      grid-template-areas: 'amazon cover title user' 'amazon cover subtitle user' 'amazon cover author user' 'amazon cover date user' 'amazon cover rating user';
      -webkit-justify-content: space-evenly;
              justify-content: space-evenly; } }
  @media (min-width: 1200px) {
    .Book {
      margin-bottom: 64px; } }

.title {
  font-size: 1.5rem;
  font-weight: 800;
  grid-area: title;
  margin-bottom: 0;
  margin-right: 8px; }
  @media (min-width: 768px) {
    .title {
      font-size: 2rem;
      margin-right: 0; } }

.subtitle {
  color: #b3b3b3;
  display: none;
  font-size: 1.25rem;
  font-weight: normal;
  grid-area: subtitle; }
  @media (min-width: 768px) {
    .subtitle {
      display: block; } }

.author {
  font-family: 'Playfair Display', serif;
  font-size: 1rem;
  grid-area: author; }

.date-read {
  color: #929292;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  grid-area: date;
  line-height: 1.5rem; }

.BookSorter {
  padding: 0;
  padding-left: 20px; }
  @media (max-width: 767px) {
    .BookSorter {
      margin-bottom: 8px; } }
  .BookSorter label {
    color: #a5a5a5;
    font-family: 'playfair Display', serif;
    font-size: 0.8em; }
  .BookSorter select,
  .BookSorter .custom-select {
    background-color: #1a1a1a;
    border: 1px solid rgba(129, 129, 129, 0.25);
    border-radius: 2px;
    color: #a5a5a5;
    font-family: 'Playfair Display', serif;
    font-size: 0.75rem; }
    .BookSorter select.form-control:focus,
    .BookSorter .custom-select.form-control:focus {
      background-color: inherit;
      color: #ccc;
      outline: none; }
    .BookSorter select.active,
    .BookSorter .custom-select.active {
      background-color: #323232;
      color: #ccc; }

.MeetingInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.Header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  padding: 0.5rem 0;
  position: relative;
  text-align: center;
  text-transform: uppercase; }
  .Header .title-back {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .Header .title-back h1 {
      color: #444;
      font-family: 'Raleway', sans-serif;
      font-size: 2.25rem;
      font-weight: 800;
      letter-spacing: 1.25rem;
      line-height: 1;
      padding: 0;
      margin: 0;
      margin-right: -1.25rem; }
      @media (min-width: 768px) {
        .Header .title-back h1 {
          color: #333;
          font-size: 5rem;
          letter-spacing: 2rem; } }
  .Header .title-front {
    -webkit-align-items: center;
            align-items: center;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    left: 0;
    letter-spacing: 0.5rem;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    right: 0;
    top: 0; }
    .Header .title-front h2 {
      display: inline-block;
      font-family: 'Raleway', sans-serif;
      font-size: 1.5rem;
      font-weight: 800;
      margin: 0;
      padding: 0; }
      @media (min-width: 768px) {
        .Header .title-front h2 {
          font-size: 2.5rem; } }

.Bookcase {
  padding: 0; }

.Navbar {
  -webkit-align-content: center;
          align-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  padding: 0 20px;
}

.Navbar .btn-primary {
  background-color: transparent;
  border: 1px solid rgba(129, 129, 129, 0.25);
  color: #a5a5a5;
  cursor: pointer;
  font-family: 'Playfair Display', serif;
  font-size: 0.8em;
  margin: 0 8px;
}

.modal-content {
  color: #555;
}

.Home {
  -webkit-align-content: center;
          align-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 16px;
  padding-bottom: 32px; }
  .Home .control-box {
    -webkit-align-content: center;
            align-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (min-width: 768px) {
      .Home .control-box {
        padding: 32px 0; } }

.App {
  background-color: #1a1a1a; }
  @media (min-width: 768px) {
    .App {
      border: 35px solid #fff; } }

