.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.close {
  background: transparent;
  border: 0;
}
