.Home {
  align-content: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-bottom: 16px;
  padding-bottom: 32px;

  .control-box {
    align-content: center;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      padding: 32px 0;
    }
  }
}
