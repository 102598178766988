.UserPicked {
  grid-area: user;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
  }

  .user-img {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .user-name {
    font-weight: bold;

    @media (min-width: 768px) {
      font-weight: nomral;
      text-align: center;
    }
  }

  .user-info {
    @media (min-width: 768px) {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .UserPicked .user-img {
    max-width: 125px;
    max-height: 125px;
    margin: 0 auto;
  }
}
