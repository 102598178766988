.AmazonIcon {
  align-items: center;
  display: flex;
  grid-area: amazon;
  height: 100%;
  justify-content: center;
  justify-self: start;

  .amazon-link {
    align-items: center;
    display: flex;
    justify-content: center;

    .amazon-logo {
      display: none;
      fill: #929292;

      @media (min-width: 768px) {
        display: block;
      }
    }

    @media (min-width: 768px) {
      background-color: #323232;
      border-radius: 50%;
      height: 50px;
      width: 50px;

      .mobile-link {
        display: none;
      }
    }

    &:hover {
      background-color: #fff;

      .amazon-logo {
        fill: #323232;
      }
    }
  }
}
