$color-background: #3aaa1a;
$color-body-bg: #1a1a1a;
$color-books: #f1f1f1;

$colors: #3498db, #e67e22, #27ae60, #8e44ad, #16a085, #c0392b;

$thickness: 5px;
$duration: 2500;
$delay: $duration/6;

@mixin polka($size, $dot, $base, $accent) {
  background: $base;
  background-image: radial-gradient($accent $dot, transparent 0);
  background-size: $size $size;
  background-position: 0 -2.5px;
}

.Spinner {
  margin: 200px auto;
  position: relative;
}

.books_list {
  margin: 0 auto;
  padding: 0;
  width: 300px;
}

.book_item {
  animation: travel #{$duration}ms linear infinite;
  background-color: $color-background;
  border: $thickness solid $color-books;
  box-sizing: border-box;
  height: 120px;
  list-style: none;
  opacity: 0;
  position: absolute;
  top: -120px;
  transform-origin: bottom left;
  transform: translateX(300px);
  width: 40px;

  &.first {
    height: 140px;
    top: -140px;

    &::before,
    &::after {
      background-color: $color-books;
      content: '';
      left: 0;
      height: $thickness;
      position: absolute;
      top: 10px;
      width: 100%;
    }

    &::after {
      bottom: 10px;
      top: initial;
    }
  }

  &.second,
  &.fifth {
    &::before,
    &::after {
      border-bottom: $thickness solid $color-books;
      border-top: $thickness solid $color-books;
      box-sizing: border-box;
      content: '';
      height: $thickness * 3.5;
      left: 0;
      position: absolute;
      top: 10px;
      width: 100%;
    }

    &::after {
      bottom: 10px;
      top: initial;
    }
  }

  &.third {
    &::before,
    &::after {
      border: $thickness solid $color-books;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      height: 12px;
      left: 9px;
      position: absolute;
      top: 10px;
      width: 12px;
    }

    &::after {
      bottom: 10px;
      top: initial;
    }
  }

  &.fourth {
    height: 130px;
    top: -130px;

    &::before {
      border-bottom: $thickness solid $color-books;
      border-top: $thickness solid $color-books;
      box-sizing: border-box;
      content: '';
      height: $thickness * 3.5;
      left: 0;
      position: absolute;
      top: 46px;
      width: 100%;
    }
  }

  &.fifth {
    height: 100px;
    top: -100px;
  }

  &.sixth {
    height: 140px;
    top: -140px;

    &::before {
      background-color: $color-books;
      bottom: 31px;
      box-sizing: border-box;
      content: '';
      height: $thickness;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::after {
      border: $thickness solid $color-books;
      border-radius: 50%;
      bottom: 10px;
      box-sizing: border-box;
      content: '';
      height: 12px;
      left: 9px;
      position: absolute;
      width: 12px;
    }
  }

  &:nth-child(2) {
    animation-delay: #{$delay * 1}ms;
  }

  &:nth-child(3) {
    animation-delay: #{$delay * 2}ms;
  }

  &:nth-child(4) {
    animation-delay: #{$delay * 3}ms;
  }

  &:nth-child(5) {
    animation-delay: #{$delay * 4}ms;
  }

  &:nth-child(6) {
    animation-delay: #{$delay * 5}ms;
  }
}

.shelf {
  background-color: $color-books;
  height: $thickness;
  margin: 0 auto;
  position: relative;
  width: 300px;

  &::before,
  &::after {
    animation: move #{$duration/10}ms linear infinite;
    content: '';
    left: 5%;
    height: 100%;

    @include polka(10px, 30%, $color-body-bg, rgba(255, 255, 255, 0.5));

    position: absolute;
    top: 200%;
    width: 100%;
  }

  &::after {
    left: 7.5%;
    top: 400%;
  }
}

@keyframes move {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 10px;
  }
}

@keyframes travel {
  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }

  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }

  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }

  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }

  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }

  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }

  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }

  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }

  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }

  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }

  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(0) rotateZ(-90deg);
  }
}

@for $i from 1 through 6 {
  .book_item:nth-child(#{$i}) {
    background: darken(nth($colors, $i), 10%);
    border-color: darken(nth($colors, $i), 20%);
  }
}
